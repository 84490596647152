<template>
  <div>
    <div v-if="!isAuthenticated">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on">
            <span class="text-lowercase">login</span>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="accent" dense>
            <h3 class="d-flex align-center">login</h3>
            <v-spacer></v-spacer>
            <v-btn dark text @click="dialog = false">
              <span class="text-lowercase">cancel</span>
            </v-btn>
          </v-toolbar>
          <LoginAuthentication class="mt-4"></LoginAuthentication>
        </v-card>
      </v-dialog>
    </div>

    <v-menu bottom min-width="250px" rounded offset-y v-if="isAuthenticated">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-avatar size="36px">
            <img :src="picture" :alt="name" />
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <div
          class="mx-auto text-center pt-4"
          style="border-bottom: 1px solid rgb(0, 0, 0, 0.1)"
        >
          <v-avatar size="55px">
            <img :src="picture" :alt="name" />
          </v-avatar>
          <h4 class="mt-2">{{ name }}</h4>
          <p class="caption mt-1">
            {{ email }}
          </p>
        </div>
        <v-list dense>
          <v-list-item ripple @click="navigate(admin.target)" v-if="isAdmin">
            <v-list-item-icon>
              <v-icon>fa-fw {{ admin.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ admin.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-for="(item, idx) in items"
            :key="idx"
            ripple
            @click="navigate(item.target)"
          >
            <v-list-item-icon>
              <v-icon>fa-fw {{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import { logout } from './../services/firebase'
import { routeNames } from './../router'
import LoginAuthentication from './LoginAuthentication.vue'

export default {
  components: { LoginAuthentication },
  name: 'ProfilePopup',
  computed: {
    picture: get('auth/picture'),
    name: get('auth/name'),
    email: get('auth/email'),
    isAuthenticated: get('auth/isAuthenticated'),
    isAdmin: get('auth/isAdmin')
  },
  data() {
    return {
      dialog: false,
      admin: {
        title: 'Ridgewolf Admin',
        icon: 'fad fa-shield',
        target: routeNames.admin.home
      },
      items: [
        {
          title: 'My Base Camp',
          icon: 'fad fa-mountains',
          target: routeNames.member.home
        },
        {
          title: 'My Profile',
          icon: 'fad fa-id-badge',
          target: routeNames.member.profile
        },
        { title: 'Logout', icon: 'fad fa-sign-out', target: 'logout' }
      ]
    }
  },
  methods: {
    navigate(target) {
      if (target === 'logout') {
        logout()
      } else {
        if (this.$route.name === target) {
          return
        } else {
          this.$router.push({ name: target })
        }
      }
    }
  }
}
</script>
